$body-background: #007984;
$sub-body-background: #007078;
$navbar-background: #F3E9E1;
$gray: #B3B3B3;
$white: #C6C6C6;
$zoom-screen-background: rgba(62, 60, 60, 0.90);

// TODO: Delete
$black: #515151;
$navbar-border: #D4D4D4;
$navbar-dots: #8F8F8F;
$text-light-gray: #898989;
$text-gray: #808080;
