@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/navigation';
@import 'swiper/scss/a11y';

.work-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  &.disabled {
    height: 100vh;
  }
  .button-container {
    width: 22.875rem;
    height: clamp(2.8125rem, 6vw, 4.8125rem);
    @include cta-button;
  }
  .image-wrapper, .info-wrapper {
    width: 50%;
  }
  .image-wrapper {
    position: sticky;
    top: 0;
    background-color: $sub-body-background;
    min-height: 100vh;
    .content {
      margin: 12.5rem auto 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        height: auto;
        // box-shadow: 0.1875rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.12);
        border-radius: 1rem;
        &:hover {
          cursor: -moz-zoom-in;
          cursor: -webkit-zoom-in;
          cursor: zoom-in;
        }
      }
      .single-image-container, .swiper {
        width: 100%;
      }
      .single-image-container {
        text-align: center;
        // height: clamp(10rem, 30vw, 35rem);
        padding-bottom: 2rem;
        height: 100%;
        img {
          width: clamp(20rem, 41vw, 34.375rem);
        }
      }
      .swiper {
        padding-bottom: 1rem;
        &-wrapper {
          align-items: center;
        }
        &-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 50%;
          }
        }
      }
      .pagination-button-container {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
        margin-bottom: 4rem;
        .swiper-pagination-bullet {
          width: 0.7rem;
          height: 0.7rem;
          opacity: 0.6 !important;
          background: $gray !important;
          &-active {
            background: $white;
            opacity: 1 !important;
          }
        }
      }
      .navigation-buttons {
        display: flex;
        justify-content: space-between;
        width: clamp(9.125rem, 20vw, 11.125rem);
        padding-bottom: 2rem;
        @include previous-navigation-button;
        @include next-navigation-button
      }
    }
  }
  .zoom-screen {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    background-color: $zoom-screen-background;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
      top: 5rem;
      padding-bottom: 3rem;
      width: 75%;
      pointer-events: none;
    }
    svg {
      position: fixed;
      top:  clamp(1.5rem, 6vw, 2.5vw);
      right: clamp(2rem, 8vw, 5rem);
      fill: $white;
      width: 2rem;
      height: 2rem;
      z-index: 10;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .info-wrapper {
    &.disabled {
      height: 100vh;
      overflow: hidden;
    }
    .contents {
      width: 90%;
      margin: 12.5rem auto 0 auto;
      padding-bottom: 2.3rem;
      @include light-body-paragraph;
      .title-wrapper, .description {
        margin-bottom: 2.5rem;
      }
      .title-wrapper {
        @include work-header;
      }
      .description {
        p {
          white-space: pre-wrap;
        }
      }
      .attributes {
        margin-bottom: 2.5rem;
        .attribute {
          margin-bottom: 1rem;
          span:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .price-wrapper {
        padding-top: 2.5rem;
        border-top: 0.0625rem solid $white;
        margin-bottom: 2.5rem;
        @include emphasis-body-paragraph;
        @include price;
      }
      .neighboring-works-link-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 4rem;
        .work-link {
          display: flex;
          align-items: center;
          svg {
            transition: transform $transition-duration;
          }
        }
        #previous-work-link {
          &:hover {
            svg {
              transform: translateX(-0.0938rem);
            }
          }
        }
        #next-work-link {
          &:hover {
            svg {
              transform: translateX(0.0938rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 61.875rem) {
  .work-container {
    display: block;
    .image-wrapper, .info-wrapper {
      width: 100%;
    }
    .image-wrapper {
      min-height: 0;
      height: auto !important;
      padding: 8rem 0 4rem 0;
      .content {
        margin-top: 0;
        .swiper {
          height: auto;
          max-width: 90%;
          &-slide {
            img {
              max-width: 100%;
            }
          }
        }
        .single-image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 85%;
          img {
            width: 80%;
            height: auto;
          }
        }
      }
    }
    .info-wrapper {
      overflow-y: hidden;
      .contents {
        margin: clamp(2.5rem, 4vw, 12.5rem) auto 0 auto;
      }
    }
  }
}

@media screen and (max-width: 30.3125rem) {
  .work-container {
    .info-wrapper {
      .button-container {
        width: 100%;
      }
    }
  }
}
