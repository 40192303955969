/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:focus {
    outline: none;
  }
  &-inner {
    background-color: $white;
  }
  &-box {
    width: 40px;
    height: 10px;
    pointer-events: none;
    display: inline-block;
    position: relative;
  }
  &-inner {
    display: block;
    pointer-events: none;
    top: 50%;
    margin-top: -2px;
    top: 5px;
    &, &::before, &::after {
      width: 40px;
      height: 4px;
      background-color: $white;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &:before, &::after {
      content: "";
      display: block;
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  }
  &--spin {
    .hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;
      }
      &::after {
        transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &.is-active {
      .hamburger-inner {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease-out,
          opacity 0.1s 0.12s ease-out;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

.hamburger-menu {
  width: 100vw;
  height: 100vh;
  background-color: $body-background;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: -1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  &:focus-visible {
    outline: none;
  }
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  .contents {
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    a {
      text-decoration: none;
    }
    .links {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      position: relative;
      top: 150px;
      a {
        color: $white;
        margin-bottom: 20px;
      }
    }
    ul {
      list-style: none;
      li {
        display: block;
        margin-right: 15px;
        margin-bottom: 10px;
        padding-bottom: 21px;
        a, a:visited {
          color: $white;
        }
      }
    }
    .sns-wrapper {
      display: flex;
      justify-content: space-between;
      // margin: 0 auto;
      padding-left: 40px;
      width: clamp(7rem, 30vw, 12.5rem);
    }
  }
}
