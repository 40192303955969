$body-paragraph-letter-spacing: 0.0625rem;

@mixin common-styles {
  color: $white;
  margin: 0;
}

@mixin common-light-body-paragraph-styles {
  letter-spacing: $body-paragraph-letter-spacing;
  font-family: 'Eina Light', sans-serif;
  @include common-styles;
}

@mixin heading-common-styles {
  @include common-styles;
  font-family: 'Corporate Logo Rounded';
}

@mixin section-header {
  h1 {
    font-size: clamp(2rem, 11vw, 7.5rem);
    @include heading-common-styles;
  }
}

@mixin work-header {
  h1 {
    font-size: clamp(1.5rem, 10vw, 5rem);
    @include heading-common-styles;
  }
}

@mixin price {
  h2 {
    font-family: 'Eina Bold', sans-serif;
    font-size: clamp(1.5rem, 8vw, 5rem);
    letter-spacing: $body-paragraph-letter-spacing;
    @include common-styles;
  }
}

@mixin nav-links {
  a {
    font-size: 1rem;
    @include common-light-body-paragraph-styles;
    }
}

@mixin light-body-paragraph {
  p {
    font-size: clamp(1rem, 2vw, 1.25rem);
    @include common-light-body-paragraph-styles;
  }
}

@mixin regular-body-paragraph {
  p {
    font-family: 'Eina Regular', sans-serif;
    color: $white;
    letter-spacing: 0.0625rem;
    @include common-styles;
  }
}

@mixin emphasis-body-paragraph {
  p {
    font-family: 'Eina Regular', sans-serif;
    font-size: clamp(1rem, 5vw, 1.5rem);
    letter-spacing: 0.0625rem;
    @include common-styles;
  }
}

// TODO: Delete

@mixin h1 {
  font-family: 'Corporate Logo Rounded';
  font-weight: 100;
  color: $white;
}

@mixin h1-stroke {
  font-family: 'Branch';
  margin: 0;
  -webkit-text-stroke: 0.06rem $white;
  font-weight: 100;
}

@mixin h2-stroke {
  font-family: 'Branch';
  margin: 0;
  font-weight: 100;
  -webkit-text-stroke: 0.06rem $white;
}

@mixin h3-stroke {
  font-family: 'Eina Bold', sans-serif;
  font-size: clamp(1rem, 5vw, 1.5625rem);
  margin: 0;
  -webkit-text-stroke: 0.01rem $white;
}

@mixin p {
  font-family: 'Eina Light', sans-serif;
  font-weight: 100;
  font-size: clamp(0.75rem, 3vw, 1rem);
  margin: 0;
  color: $white;
}

@mixin small {
  font-family: 'Eina Bold', sans-serif;
  color: $gray;
  font-size: clamp(0.75rem, 3vw, 0.938rem);
  margin: 0;
}

@mixin a {
  a {
    font-family: 'Eina Light', sans-serif;
    margin: 0;
    text-decoration: none;
    &, &:visited {
      color: $white;
    }
  }
}
