@mixin work-card {
  .work-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: clamp(9.3rem, 28vw, 27.1875rem);
    .thumbnail {
      height: clamp(12.4375rem, 45vw, 35.75rem);
      width: 100%;
      border-radius: clamp(0.5rem, 2vw, 1.5rem);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    @include light-body-paragraph;
    word-break: break-all;
  }
}

@media screen and (max-width: 35.5rem) {
  .work-card {
    width: clamp(9.3rem, 42vw, 27.1875rem) !important;
    .thumbnail {
      height: clamp(12.4375rem, 50vw, 35.75rem) !important;
    }
  }
}
