@mixin loading-screen {
  .loading-screen {
    height: 100%;
    width: 100%;
    background-color: $body-background;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 100;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include light-body-paragraph;
    &.loaded {
      opacity: 0;
      pointer-events: none;
    }
  }
}
