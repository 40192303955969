@import '../colors.scss';

header {
  z-index: 99;
  width: 100%;
  position: fixed;
  top: 1.3rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  transition-delay: 0.1s;
  &.loaded {
    pointer-events: all;
    opacity: 1;
  }
  .desktop-navbar {
    right: 30px;
    margin: 0;
    ul {
      display: flex;
      align-items: flex-start;
      gap: clamp(1.5rem, 4vw, 3.5rem);
      list-style: none;
      justify-content: space-between;
    }
  }
  .desktop-navbar, .mobile-navbar {
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 95%;
    #logo {
      left: 30px;
      font-family: 'Stella';
      font-size: clamp(2.7rem, 10vw, 3.75rem);
      &:focus-visible {
        outline: none;
      }
      &:visited {
        color: $white;
      }
    }
    ul {
      li {
        @include nav-links;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  header {
    .desktop-navbar {
      display: flex;
    }
    .mobile-navbar {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  header {
    .desktop-navbar {
      display: none;
    }
    .mobile-navbar {
      display: flex;
    }
  }
}
