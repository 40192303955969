.about-wrapper {
  color: $white;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  .contents {
    position: relative;
    top: 10rem;
    width: clamp(10rem, 90vw, 80rem);
    margin: 0 auto;
    .title-section {
      display: flex;
      flex-direction: column;
      @include section-header;
    }
    .description {
      margin-top: clamp(5rem, 24vw, 8rem);
    }
    .description, .credits {
      width: 100%
    }
    p {
      @include p;
    }
    .sns-wrapper {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      width: 9.375rem;
      svg {
        width: 1.25rem;
      }
    }
    .credits {
      margin: clamp(4rem, 20vw, 8.5rem) 0 2rem 0;
      padding-bottom: 2rem;
      .credit-small-title {
        @include regular-body-paragraph;
      }
      .links {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        max-width: 85%;
        p {
          padding: 0;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 62.5rem) {
  .about-wrapper {
    .contents {
      .credits {
        padding-bottom: 0;
        .links {
          flex-direction: column;
          p {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}
