.gallery-wrapper {
  @include section-header;
  width: clamp(18.75rem, 97vw, 91.5625rem);
  margin: clamp(7rem, 20vw, 11.56rem) auto 0 auto;
  padding-bottom: 4rem;
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.5rem;
    .filter-buttons {
      display: flex;
      align-items: center;
      gap: clamp(0.5rem, 3vw, 2.5rem);
      @include filter-button;
    }
  }
  .works-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, clamp(10rem, 28vw, 27.1875rem));
    grid-gap: clamp(2rem, 6vw, 4rem) clamp(1rem, 6vw, 5rem);
    @include work-card;
  }
}

@media screen and (max-width: 35.5rem) {
  .gallery-wrapper {
    overflow-x: hidden;
    .title-wrapper {
      flex-direction: column;
      padding-left: 0.875rem;
      .filter-buttons {
        padding-top: 1rem;
      }
    }
    .works-wrapper {
      justify-content: center;
      grid-template-columns: repeat(auto-fill, clamp(5rem, 42vw, 14rem));
    }
  }
}
